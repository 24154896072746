<div class="cropper" *ngIf="imageUrl">
  <section class="page-head-new" [ngStyle]="{ backgroundImage: 'url(' + imageUrl + ')' | ssrEmptyString }" [attr.aria-label]="imageAlt">
    <div class="custom-gradient" *ngIf="componentObject?.gradient"></div>
    <div class="wrapper top-wrapper">
      <h1 class="heading">
        <span *ngIf="title">{{ title }}</span>
        <span class="date-header" *ngIf="eventDateFrom">{{ dateLabel(eventDateFrom, eventDateTill) }}</span>
      </h1>
    </div>
  </section>
  <div class="lead-extension">
    <div class="figure figure-{{ figureImage }}"></div>
    <div class="wrapper lead-wrapper figure-{{ figureImage }}-lead">
      <div class="text-box" *ngIf="text" [innerHtml]="shortenedLead"></div>
      <span *ngIf="showReadMore" class="more" [appBackgroundColor]="'white'" (click)="onReadMore()">
        {{ 'TEXT.readMore' | translate }} <i class="icon icon-arrow-down-more-red"></i
      ></span>
      <span *ngIf="showReadLess" class="less" [appBackgroundColor]="'white'" (click)="onReadLess()">
        {{ 'TEXT.readLess' | translate }} <i class="icon icon-arrow-down-more-red"></i
      ></span>
    </div>
  </div>
</div>

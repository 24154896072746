import { Routes } from '@angular/router';
import { BaseComponent } from './base/base.component';
import { BaseResolver } from './base/base.resolver';

export const appRoutes: Routes = [
  {
    path: '',
    component: BaseComponent,
    resolve: { data: BaseResolver },
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'weather',
        loadChildren: () => import('src/app/weather-page/weather-page.module').then((m) => m.WeatherPageModule),
      },
      {
        path: 'articles/:slug',
        loadChildren: () => import('src/app/article-page/article-page.module').then((m) => m.ArticlePageModule),
      },
      {
        path: 'events/:slug',
        loadChildren: () => import('src/app/event-detail-page/event-detail-page.module').then((m) => m.EventDetailPageModule),
      },
      {
        path: 'trips/:slug',
        loadChildren: () => import('src/app/trip/trip.module').then((m) => m.TripModule),
      },
      {
        path: 'collection/:slug',
        loadChildren: () => import('src/app/collection-page/collection-page.module').then((m) => m.CollectionPageModule),
      },
      {
        path: 'category/:slug',
        loadChildren: () =>
          import('src/app/category-collection-page/category-collection-page.module').then((m) => m.CategoryCollectionPageModule),
      },
      {
        path: 'downloads',
        loadChildren: () => import('src/app/downloads/downloads.module').then((m) => m.DownloadsModule),
      },
      {
        path: 'tourinform-office',
        loadChildren: () => import('src/app/tourinform/tourinform.module').then((m) => m.TourinformModule),
      },
      {
        path: 'destination/:slug',
        loadChildren: () => import('src/app/destination-page/destination-page.module').then((m) => m.DestinationPageModule),
      },
      {
        path: 'search',
        children: [
          {
            path: '',
            loadChildren: () => import('src/app/search/search.module').then((m) => m.SearchModule),
          },
          {
            path: ':slug',
            loadChildren: () => import('src/app/search-details/search-details.module').then((m) => m.SearchDetailsModule),
          },
        ],
      },
      {
        path: 'attraction-finder',
        children: [
          {
            path: '',
            loadChildren: () => import('src/app/attraction-finder/attraction-finder.module').then((m) => m.AttractionFinderModule),
          },
          {
            path: ':slug',
            loadChildren: () => import('src/app/search-details/search-details.module').then((m) => m.SearchDetailsModule),
          },
        ],
      },
      // {
      //   path: 'bucket-list',
      //   loadChildren: () => import('src/app/bucket-list/bucket-list.module').then(m => m.BucketListModule),
      // },
      {
        path: 'experiences',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('src/app/experience-collection/experience-collection.module').then((m) => m.ExperienceCollectionModule),
          },
          {
            path: 'influencer/:id',
            loadChildren: () => import('src/app/experience-group/experience-group.module').then((m) => m.ExperienceGroupModule),
          },
          {
            path: ':slug',
            loadChildren: () => import('src/app/experience-page/experience-page.module').then((m) => m.ExperiencePageModule),
          },
        ],
      },
      {
        path: 'campaign/:slug',
        loadChildren: () => import('src/app/campaign-page/campaign-page.module').then((m) => m.CampaignPageModule),
        data: { segment: 'campaign' },
      },
      {
        path: 'restaurant/:slug',
        loadChildren: () => import('src/app/campaign-page/campaign-page.module').then((m) => m.CampaignPageModule),
        data: { segment: 'restaurant' },
      },
      {
        path: 'refugee/:slug',
        loadChildren: () => import('src/app/campaign-page/campaign-page.module').then((m) => m.CampaignPageModule),
        data: { segment: 'refugee' },
      },
      {
        path: 'summer-campaign/:slug',
        loadChildren: () => import('src/app/campaign-page/campaign-page.module').then((m) => m.CampaignPageModule),
        data: { segment: 'summercampaign' },
      },
      // {
      //   path: 'list-view',
      //   loadChildren: () => import('src/app/listing-page/listing-page.module').then(m => m.ListingPageModule),
      // },
      {
        path: '404',
        loadChildren: () => import('src/app/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
      },
      {
        path: ':slug',
        loadChildren: () => import('src/app/static-page/static-page.module').then((m) => m.StaticPageModule),
      },
      {
        path: '**',
        data: { skipRouteLocalization: true },
        redirectTo: '404',
      },
    ],
  },
];

import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  Injector,
  ComponentFactoryResolver,
  ApplicationRef,
  Type,
  NgZone,
} from '@angular/core';
import { TrendencyUtilsService } from 'trendency/utils';
import { ExperienceCustomMarkerComponent } from './custom-markers/experience-custom-marker/experience-custom-marker.component';
import { DialogConfig, DialogInjector } from './custom-marker-injector';
import { Subject } from 'rxjs';
import { TrendencyGoogleMapService } from 'trendency/utils/services/google-map.service';
import { takeUntil } from 'rxjs/operators';
import { ILatLng } from '../../shared.definitions';
import MarkerClusterer from '@googlemaps/markerclustererplus';

export interface IMarker {
  coordinate: ILatLng;
  component?: Type<any>;
  data?: any;
}

export const DEFAULT_PIN = '/assets/images/mapicons/latnivalo-kereso-pin-single.svg';
export const TOUR_INFOPOINT_PIN = '/assets/images/mapicons/tourinform-infopoint.svg';
export const TOUR_OFFICE_PIN = '/assets/images/mapicons/tourinform-office.svg';

@Component({
  selector: 'app-full-width-map',
  templateUrl: './full-width-map.component.html',
  styleUrls: ['./full-width-map.component.scss'],
})
export class FullWidthMapComponent implements OnInit, OnDestroy {
  @ViewChild('gmap', { static: true }) gmapElement: ElementRef;
  @Input() mapUIDisabled = true;
  @Input() openFirst = false;
  @Input() fullHeight: boolean;
  private _centerLatLng = { lat: 47.513228, lng: 19.053289 };
  @Input() set centerLatLng(latLng: ILatLng) {
    if (latLng) {
      this._centerLatLng = latLng;
      this.centerTo(latLng);
    }
  }

  private _markers: IMarker[];
  @Input() set markers(markers: IMarker[]) {
    this._markers = markers;
    if (this.afterViewInit) {
      this.addMarkers();
    }
  }

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  public map: google.maps.Map;
  public options: google.maps.MapOptions;
  //oldImagePath '/assets/images/mapicons/marker.webp';
  private cluster;
  private readonly clusterOptions = {
    maxZoom: 12,
    styles: [
      {
        textColor: '#FFFFFF',
        textSize: 14,
        url: '/assets/images/mapicons/ellipse.webp',
        scaledSize: typeof google === 'object' ? new google.maps.Size(55, 55) : null,
        height: 55,
        width: 55,
        anchor: [0, 0],
        backgroundSize: 'center',
      },
    ],
  };
  private currentMarkers = [];
  private afterViewInit = false;
  private currentlyOpenInfoWindow;

  constructor(
    private readonly utilsService: TrendencyUtilsService,
    private readonly injector: Injector,
    private readonly resolver: ComponentFactoryResolver,
    private readonly appRef: ApplicationRef,
    private readonly gMapService: TrendencyGoogleMapService,
    private readonly zone: NgZone
  ) {}

  ngOnInit(): void {
    if (this.utilsService.isBrowser()) {
      this.gMapService.initGoogle();
      this.gMapService.googleAPIisReady.pipe(takeUntil(this.destroy$)).subscribe((val) => {
        if (val) {
          this.initMap();
          this.addMarkers();
          this.openFirstMarker();
          this.afterViewInit = true;
        }
      });
    }
  }

  initMap(): void {
    if (this.utilsService.isBrowser()) {
      this.options = {
        disableDefaultUI: this.mapUIDisabled,
        zoom: 7,
        center: this._centerLatLng,
      };
      this.map = new google.maps.Map(this.gmapElement.nativeElement, this.options);
      this.map.addListener('click', () => {
        if (this.currentlyOpenInfoWindow) {
          this.currentlyOpenInfoWindow.close();
          this.currentlyOpenInfoWindow = null;
        }
      });
      this.cluster = new MarkerClusterer(this.map, [], this.clusterOptions);
    }
  }

  private addMarkers(): void {
    if (this.utilsService.isBrowser()) {
      this.clearMap();
      if (this._markers?.length) {
        this._markers.forEach((element) => {
          if (element.component) {
            this.createCustomWindow(element);
          } else {
            this.createSimpleMarker(element);
          }
        });
        this.cluster.addMarkers(this.currentMarkers);
        if (this._markers?.length === 1) {
          const coordinate = this._markers[0].coordinate;
          if (coordinate) {
            this.map.setCenter(coordinate);
          }
        }
      }
    }
  }

  openFirstMarker(): void {
    if (this.openFirst === true && this._markers && this._markers.length) {
      setTimeout(() => {
        const element = this._markers[0];
        const infoWindow = new google.maps.InfoWindow({ disableAutoPan: true });
        const compRef = this.generateComponent(element.component, element.data);
        const image = this.setPinByType(element.data.type);
        const marker = new google.maps.Marker({
          map: this.map,
          position: element.coordinate,
          icon: image,
        });
        this.zone.run(() => {
          this.onMarkerClick(marker, compRef, infoWindow);
        });
      }, 0);
    }
  }

  clearMap(): void {
    if (this.currentMarkers.length) {
      this.currentMarkers.forEach((element) => {
        element.setMap(null);
      });
      this.currentMarkers = [];
      this.cluster.clearMarkers();
    }
  }

  createSimpleMarker(markerElement): void {
    const image = this.setPinByType(markerElement.data.type);
    const marker = new google.maps.Marker({
      map: this.map,
      position: markerElement.coordinate,
      icon: image,
    });
    this.currentMarkers.push(marker);
  }

  createCustomWindow(element): void {
    const image = this.setPinByType(element.data.type);
    const marker = new google.maps.Marker({
      map: this.map,
      position: element.coordinate,
      icon: image,
    });
    this.currentMarkers.push(marker);
    const infoWindow = new google.maps.InfoWindow({ disableAutoPan: true });
    const compRef = this.generateComponent(element.component, element.data);
    marker.addListener('click', () => {
      if (this.currentlyOpenInfoWindow) {
        this.currentlyOpenInfoWindow.close();
      }
      const markerPos = marker.getPosition();
      this.panMap(markerPos.lat(), markerPos.lng());
      infoWindow.addListener('closeclick', () => {
        this.appRef.detachView(compRef.hostView);
      });
      this.zone.run(() => {
        this.onMarkerClick(marker, compRef, infoWindow);
      });
      this.currentlyOpenInfoWindow = infoWindow;
    });
  }

  panMap(lat: number, lng: number): void {
    const zoom = this.map.getZoom();
    let zoomModifier = 1;
    if (zoom == 8) zoomModifier = 0.4;
    if (zoom == 9) zoomModifier = 0.25;
    if (zoom == 10) zoomModifier = 0.15;
    if (zoom == 11) zoomModifier = 0.1;
    if (zoom == 12) zoomModifier = 0.05;
    if (zoom == 13) zoomModifier = 0.02;
    if (zoom == 14) zoomModifier = 0.01;
    if (zoom == 15) zoomModifier = 0.005;
    if (zoom == 16) zoomModifier = 0.002;
    if (zoom == 17) zoomModifier = 0.001;
    if (zoom >= 18) zoomModifier = 0.0005;
    this.map.panTo({ lat: lat + zoomModifier, lng: lng });
  }

  generateComponent(component: Type<any>, data?): any {
    const compFactory = this.resolver.resolveComponentFactory(component || ExperienceCustomMarkerComponent);
    if (data) {
      const map = new WeakMap();
      map.set(DialogConfig, { data: data });
      return compFactory.create(new DialogInjector(this.injector, map));
    }
    return compFactory.create(this.injector);
  }

  onMarkerClick(marker, compref, infowindow): void {
    this.appRef.attachView(compref.hostView);
    const div = document.createElement('div');
    div.appendChild(compref.location.nativeElement);
    infowindow.setContent(div);
    infowindow.open(this.map, marker);
  }

  centerTo(latLng: ILatLng): void {
    if (this.map) {
      // markerer click event ....
      // console.log(this.currentMarkers)
      this.map.panTo(latLng);
    }
  }

  setPinByType(type: any): string {
    if (!type) {
      return DEFAULT_PIN;
    }
    if (type.slug === 'tourinform_office') {
      return TOUR_OFFICE_PIN;
    }
    if (type.slug === 'tourinform_infopoint') {
      return TOUR_INFOPOINT_PIN;
    }
    return DEFAULT_PIN;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

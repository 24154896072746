import {
  Component,
  OnInit,
  PLATFORM_ID,
  Inject,
  Renderer2,
  OnDestroy,
  Input,
  ElementRef,
  ViewChild,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ComponentMapService } from '../shared/services/component-map.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IContentLang } from '../shared/shared.definitions';
import { SharedService } from '../shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { TrendencyUtilsService } from 'trendency/utils';
import { WeatherService } from '../shared/services/weather.service';
import { BaseService } from '../base/base.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('mainNav', { static: true }) mainNavObj: ElementRef;
  @ViewChild('headMain', { static: true }) headMain: ElementRef;

  @Input() footerData: any;
  @Input() menu: any;
  @Input() languages: IContentLang[];

  isSticky = true;
  isStickyMobile = true;
  isHomePage = true;
  actualNavId = 0;
  headOpacity = 1;
  lastScrollPos = 0;
  prevScrollPos = 0;
  menuWhatToDo: any;
  menuWhereToGo: any;
  menuHungaryFor: any;
  menuPlanYourTrip: any;
  title: string;
  mapIconSubtitle0: string;
  mapIconSubtitle1: string;
  mapIconSubtitle2: string;
  mapIconKey: string;
  thematicIconKey: string;
  imageUrl: string;
  imageAlt: string;
  gradient: boolean;
  youtubeUrl: string;
  currentCelsius: number;
  currentSettlement = 'Budapest';
  currentWeatherImageUrl: string;
  isSearchDropdownOpened = false;
  isProduction: boolean;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly componentMapService: ComponentMapService,
    private readonly translate: TranslateService,
    private readonly sharedService: SharedService,
    private readonly utilsService: TrendencyUtilsService,
    private readonly weatherService: WeatherService,
    private readonly baseService: BaseService
  ) {
    this.renderer.listen('window', 'scroll', () => {
      this.stickyHandle();
      this.handleScrollDirection();
    });

    this.renderer.listen('window', 'click', (evt) => {
      this.globalMouseClick(evt);
    });
  }

  ngAfterViewInit(): void {
    if (this.router.url === '/404') {
      this.headMain.nativeElement.style.backgroundColor = '#FF0063';
    }
  }

  get isMobileMenuOpen$(): Observable<boolean> {
    return this.baseService.isMobileMenuOpen$;
  }

  ngOnInit(): void {
    this.isProduction = environment.production;

    if (this.utilsService.isBrowser()) {
      this.weatherService
        .getWeatherDataBySettlements$(this.currentSettlement)
        .pipe(takeUntil(this.destroy$))
        .subscribe((weatherData) => {
          this.currentCelsius = weatherData?.selected?.current?.celsius;
          this.currentWeatherImageUrl = weatherData?.selected?.current?.iconUrl;
        });
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.onInitExtended();
      });

    this.componentMapService.mainPageHero$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.title = value?.componentObject?.title;
        this.thematicIconKey = value?.componentObject?.thematicIcon?.key;
        this.mapIconKey = value?.componentObject?.mapIcon?.key;
        this.mapIconSubtitle0 = value?.componentObject?.mapIconSubtitle0;
        this.mapIconSubtitle1 = value?.componentObject?.mapIconSubtitle1;
        this.mapIconSubtitle2 = value?.componentObject?.mapIconSubtitle2;
        this.imageUrl =
          this.sharedService.isMobile() && value.componentObject?.mobileThumbnailImage?.url
            ? value.componentObject?.mobileThumbnailImage?.url
            : value.componentObject?.image?.url;
        this.imageAlt =
          this.sharedService.isMobile() && value.componentObject?.mobileThumbnailImage?.image?.title
            ? value.componentObject?.mobileThumbnailImage?.image?.title
            : value.componentObject?.image?.image?.title;
        this.gradient = value?.componentObject?.gradient || value.componentObject?.gradient == null;
        this.youtubeUrl = !this.sharedService.isMobile() && this.sharedService.getClipFromSourceOrLink(value?.componentObject?.youtubeLink);
      }
    });
  }

  ngOnChanges(): void {
    this.onInitExtended();
    this.menuWhatToDo = this.menu?.header_what_to_do;
    this.menuWhereToGo = this.menu?.header_where_to_go;
    this.menuHungaryFor = this.menu?.header_hungary_for;
    this.menuPlanYourTrip = this.menu?.header_plan_your_trip;
  }

  onInitExtended(): void {
    const url = this.router.routerState.snapshot.url;
    const actualPath = url.split('?')[0].split('/');
    const actualLang = this.translate.currentLang;
    this.setDocumentLang(actualLang);
    this.isHomePage = actualPath.length === 2 && ((actualLang === 'hu' && actualPath[1] === '') || actualPath[1] === actualLang);

    if (isPlatformBrowser(this.platformId)) {
      this.stickyHandle();
      this.handleScrollDirection();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  closeMobileMenu(): void {
    this.actualNavId = 0;
    this.baseService.toggleMobileMenu(false);
  }

  toggleMobileMenu(): void {
    this.baseService.toggleMobileMenu();
  }

  closeNavContent(): void {
    this.actualNavId = 0;
  }

  toggleNavContantOutCall(): void {
    /* called only from footer main menu links */

    this.baseService.toggleMobileMenu(true);
    this.toggleNavContent(null);
  }

  toggleNavContent(id: number): void {
    if (id !== this.actualNavId) {
      this.actualNavId = id;
    } else {
      this.closeNavContent();
    }

    if (this.isHomePage) {
      this.mainNavObj.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  stickyHandle(): void {
    const scrollPos = window.scrollY || window.pageYOffset;
    this.stickyHandleDesktop(scrollPos);
    this.stickyHandleMobile(scrollPos);
    this.lastScrollPos = scrollPos;
  }

  handleScrollDirection(): void {
    if (isPlatformBrowser(this.platformId)) {
      const newScrollPos = window.pageYOffset;
      if (newScrollPos > this.prevScrollPos && !this.isHomePage) {
        this.closeNavContent();
      } else if (newScrollPos > 731 && this.isHomePage && window.innerWidth > 768) {
        this.closeNavContent();
      }
      this.prevScrollPos = newScrollPos;
    }
  }

  stickyHandleMobile(scrollPos: number): void {
    const border = 0;

    if (scrollPos === 0) {
      this.isStickyMobile = true;
    } else if (scrollPos > border && this.lastScrollPos < scrollPos) {
      if (!this.baseService.isMobileMenuOpen()) {
        this.isStickyMobile = false;
      }
    } else {
      this.isStickyMobile = true;
    }
  }

  stickyHandleDesktop(scrollPos: number): void {
    let border = 36;

    if (this.isHomePage) {
      const page = document.getElementsByClassName('app-root')[0] as HTMLElement;
      const pageWidth = page.offsetWidth;
      border = pageWidth * 0.29; /* TODO MORE PRECISE CALC */

      let val = border - scrollPos;
      if (val < 0) {
        val = 0;
      }

      const opBorder = border * 0.7;
      this.headOpacity = val / opBorder / 0.5;
    }

    if (scrollPos > border && !this.isSticky) {
      this.isSticky = true;
    } else if (scrollPos <= border && this.isSticky) {
      this.isSticky = false;
    }
  }

  globalMouseClick(event): void {
    if (this.baseService.isMobileMenuOpen()) {
      return;
    }

    if (this.actualNavId !== 0) {
      const elem = event.target;

      if (elem.className.indexOf('nav-content-wrapper') !== -1) {
        this.actualNavId = 0;
        return;
      }

      const mainNavParent = this.getClosest(elem, 'main-nav');
      if (mainNavParent === null) {
        this.actualNavId = 0;
      }

      if (elem.tagName === 'A' || (elem.parentNode && elem.parentNode.tagName === 'A')) {
        this.actualNavId = 0;
      }
    }
  }

  getClosest = function (elem, classToTest): any {
    while (elem) {
      elem = elem.parentNode;

      if (elem?.className === 'app-root') {
        return null;
      }

      if (elem?.className) {
        if (elem.className.indexOf(classToTest) !== -1) {
          return elem;
        }
      }
    }

    return null;
  };

  onClickToggleSearchBar(): void {
    this.isSearchDropdownOpened = !this.isSearchDropdownOpened;
  }

  setDocumentLang(lang: string): void {
    this.document.documentElement.lang = lang;
  }
}

import { Injectable } from '@angular/core';
/*import { TrendencyReqService } from 'trendency/http';*/
import {
  Forecast,
  ForecastData,
  ForecastReduced,
  ForecastMapped,
  MappedWeatherData,
  WeatherIconData,
  ForecastBySettlement,
  WeatherBySettlement,
  MergedForecastAndWeather,
  BundledWeatherData,
  BundledCurrentWeatherData,
  WeatherMeta,
} from '../shared.definitions';
import { forkJoin, Observable } from 'rxjs';
import { getDay, getHours, getTime, getMonth, getDate } from 'date-fns';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WeatherService {
  // only for develop
  /*private readonly weatherApiUrl = environment.weatherApiUrl;*/
  private readonly dayNames = [
    'label.sunday',
    'label.monday',
    'label.tuesday',
    'label.wednesday',
    'label.thursday',
    'label.friday',
    'label.saturday',
  ];
  private readonly monthNames = [
    'label.jan',
    'label.feb',
    'label.mar',
    'label.apr',
    'label.may',
    'label.jun',
    'label.jul',
    'label.aug',
    'label.sep',
    'label.oct',
    'label.nov',
    'label.dec',
  ];

  private readonly weatherType = {
    1: { iconUrl: 'assets/images/weather/icon_sunny_64x64(1).svg', iconMsg: 'derült' },
    2: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'kissé felhős' },
    3: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'közepesen felhős' },
    4: { iconUrl: 'assets/images/weather/2_icon_cloudy_64x64.svg', iconMsg: 'erősen felhős' },
    5: { iconUrl: 'assets/images/weather/2_icon_cloudy_64x64.svg', iconMsg: 'borult' },
    6: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'fátyolfelhős' },
    7: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'köd' },
    8: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'zúzmarás köd' },
    9: { iconUrl: 'assets/images/weather/icon_sunny_64x64(1).svg', iconMsg: 'derült, párás' },
    10: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'közepesen felhős, párás' },
    11: { iconUrl: 'assets/images/weather/2_icon_cloudy_64x64.svg', iconMsg: 'borult, párás' },
    12: { iconUrl: 'assets/images/weather/icon_cloudy_sunny_64x64(1).svg', iconMsg: 'erősen fátyolfelhős' },
    101: { iconUrl: 'assets/images/weather/2_icon_rainy_64x64(1).svg', iconMsg: 'szitálás' },
    102: { iconUrl: 'assets/images/weather/2_icon_rainy_64x64(1).svg', iconMsg: 'eső' },
    202: { iconUrl: 'assets/images/weather/2_icon_thunderstorm_64x64(1).svg', iconMsg: 'kiadós eső' },
    103: { iconUrl: 'assets/images/weather/2_icon_rainy_64x64(1).svg', iconMsg: 'zápor' },
    203: { iconUrl: 'assets/images/weather/2_icon_thunderstorm_64x64(1).svg', iconMsg: 'erős zápor' },
    104: { iconUrl: 'assets/images/weather/2_icon_rainy_windy_64x64.svg', iconMsg: 'zivatar esővel' },
    304: { iconUrl: 'assets/images/weather/2_icon_rainy_windy_64x64.svg', iconMsg: 'zivatar záporral' },
    105: { iconUrl: 'assets/images/weather/2_icon_rainy_64x64(1).svg', iconMsg: 'ónos szitálás' },
    106: { iconUrl: 'assets/images/weather/2_icon_rainy_64x64(1).svg', iconMsg: 'ónos eső' },
    107: { iconUrl: 'assets/images/weather/2_icon_snowy_64x64.svg', iconMsg: 'hószállingózás' },
    108: { iconUrl: 'assets/images/weather/2_icon_snowy_64x64.svg(1)', iconMsg: 'havazás' },
    208: { iconUrl: 'assets/images/weather/2_icon_snowy_64x64.svg(1)', iconMsg: 'erős havazás' },
    109: { iconUrl: 'assets/images/weather/icon_ice.svg', iconMsg: 'hózápor' },
    209: { iconUrl: 'assets/images/weather/icon_ice.svg', iconMsg: 'erős hózápor' },
    110: { iconUrl: 'assets/images/weather/icon_rain_and_snow.svg', iconMsg: 'havas eső' },
    310: { iconUrl: 'assets/images/weather/icon_rain_and_snow.svg', iconMsg: 'havas eső záporral' },
    112: { iconUrl: 'assets/images/weather/icon_rain_and_snow.svg', iconMsg: 'hózivatar' },
    // Todo: refact new icon with id 500 cannot found
    // 500: { iconUrl: 'assets/images/weather/icon_rain_and_snow.svg', iconMsg: 'hózivatar' }
  };

  private readonly weatherIconPositions = [
    { top: 22, left: 4, settlement: 'Komárom', region: 'Budapest' },
    { top: 18, left: 41, settlement: 'Esztergom', region: 'Budapest' },
    { top: 17, left: 68, settlement: 'Szentendre', region: 'Budapest' },
    { top: 6, left: 83, settlement: 'Vác', region: 'Budapest' },
    { top: 20, left: 81, settlement: 'Gödöllő', region: 'Budapest' },
    { top: 34, left: 69.4, settlement: 'Dunaharaszti', region: 'Budapest' },
    { top: 43, left: 83, settlement: 'Vecsés', region: 'Budapest' },
    { top: 67, left: 20, settlement: 'Székesfehérvár', region: 'Budapest' },
    { top: 65, left: 45, settlement: 'Velence', region: 'Budapest' },
    { top: 70, left: 65, settlement: 'Ráckeve', region: 'Budapest' },
    { top: 31.5, left: 12, settlement: 'Szombathely', region: 'National' },
    { top: 22, left: 24, settlement: 'Győr', region: 'National' },
    { top: 46, left: 30, settlement: 'Veszprém', region: 'National' },
    { top: 70, left: 28, settlement: 'Pécs', region: 'National' },
    { top: 38, left: 39.5, settlement: 'Budapest', region: 'National' },
    { top: 17, left: 52, settlement: 'Miskolc', region: 'National' },
    { top: 10, left: 65, settlement: 'Nyíregyháza', region: 'National' },
    { top: 35, left: 63, settlement: 'Debrecen', region: 'National' },
    { top: 63, left: 52, settlement: 'Szeged', region: 'National' },
    { top: 50, left: 60, settlement: 'Békéscsaba', region: 'National' },
  ];

  constructor(/*private readonly reqService: TrendencyReqService, */ private readonly translate: TranslateService) {}
  public get lang(): string {
    return this.translate.currentLang;
  }

  public celsiusToFahrenheit(celsius: number): number {
    return Math.round(celsius * 1.8 + 32);
  }

  getDayName(day: string): string {
    const date = new Date(day);
    return this.dayNames[getDay(date)];
  }

  getMonthName(day: string): string {
    const date = new Date(day);
    return this.monthNames[getMonth(date)];
  }

  reducingForecastData(forecastData: ForecastData[]): ForecastReduced {
    return forecastData.reduce((a, b) => ((a[b.name] = b.value), a), {});
  }

  getWeatherIconPositions(settlement: string): WeatherIconData {
    const positions = this.weatherIconPositions.find((w) => w.settlement === settlement);
    return {
      top: positions ? positions.top : 0,
      left: positions ? positions.left : 0,
      region: positions ? positions.region : '',
    };
  }

  filterByHour(date: string, hour: number): boolean {
    return getHours(new Date(date)) === hour;
  }

  sortByDate(forecast1: Forecast, forecast2: Forecast): number {
    return getTime(new Date(forecast1.time)) - getTime(new Date(forecast2.time));
  }

  mappingForecastData(forecastData: Forecast[]): ForecastMapped[] {
    return forecastData
      .filter((day) => this.filterByHour(day.time, 0))
      .sort(this.sortByDate)
      .map((day) => {
        const forecastReduced: ForecastReduced = this.reducingForecastData(day.data);
        const forecastMapped: ForecastMapped = {
          time: day.time,
          day: this.getDayName(day.time),
          iconUrl: this.weatherType[forecastReduced.weather]?.iconUrl ?? null,
          iconMsg: this.weatherType[forecastReduced.weather]?.iconMsg ?? null,
          rainIconUrl: 'assets/images/weather/water_drops.svg',
          rainQuantity: Math.round(forecastReduced.Prec6h),
          minCelsius: Math.round(forecastReduced.TMin2),
          maxCelsius: Math.round(forecastReduced.TMax2),
          minFahrenheit: Math.round(this.celsiusToFahrenheit(forecastReduced.TMin2)),
          maxFahrenheit: Math.round(this.celsiusToFahrenheit(forecastReduced.TMax2)),
        };
        return forecastMapped;
      });
  }

  bundleWeatherData(weatherData: [ForecastBySettlement[], WeatherBySettlement[]]): MappedWeatherData[] {
    const concat: MergedForecastAndWeather[] = [...weatherData[0], ...weatherData[1]];
    const bundle = [];
    let pointer = 0;

    for (let i = 1; i < concat.length; i++) {
      if (pointer === concat.length - 1) {
        return null;
      }
      if (concat[pointer].settlement === concat[i].settlement) {
        const forecast = this.mappingForecastData(concat[pointer].forecast || concat[i].forecast);
        const measurment = (concat[pointer].measurements || concat[i].measurements).t;
        // -999 = N/A
        const celsius = measurment === -999 ? null : Math.round(measurment);
        const fahrenheit = this.celsiusToFahrenheit(celsius);
        const settlement = concat[pointer].settlement;
        const today = forecast[0];

        bundle.push({
          settlement,
          forecast,
          current: {
            celsius,
            fahrenheit,
            iconUrl: today?.iconUrl,
            iconMsg: today?.iconMsg,
            ...this.getWeatherIconPositions(settlement),
          },
        });

        pointer++;
        i = pointer;
      }
    }

    return bundle;
  }

  getMeta(weatherDataBundle: MappedWeatherData[]): WeatherMeta {
    const forecast = weatherDataBundle[0].forecast;
    const today = forecast[0];
    const lastDay = forecast[forecast.length - 1];

    return {
      startMonth: this.getMonthName(today.time),
      startDay: getDate(new Date(today.time)),
      endMonth: this.getMonthName(lastDay.time),
      endDay: getDate(new Date(lastDay.time)),
    };
  }

  getNationalData(weatherDataBundle: MappedWeatherData[]): BundledCurrentWeatherData {
    const forecast = weatherDataBundle[0].forecast;
    const today = forecast[0];
    const celsius = Math.round(
      weatherDataBundle.map((w) => w.current.celsius).reduce((a, b) => a + b) /
        weatherDataBundle.filter((w) => w.current.celsius !== null).length
    );
    const fahrenheit = this.celsiusToFahrenheit(celsius);
    const weatherIcons = weatherDataBundle.map((b) => b.current).filter((b) => b.region === 'National');

    return {
      weatherIcons,
      current: {
        celsius,
        fahrenheit,
        iconUrl: today?.iconUrl,
        iconMsg: today?.iconMsg,
      },
    };
  }

  getSelectedData(weatherDataBundle: MappedWeatherData[], selectedSettlement: string): BundledCurrentWeatherData {
    const selectedSettlementData = weatherDataBundle.find((day) => day.settlement === selectedSettlement) || weatherDataBundle[0];
    const weatherIcons = weatherDataBundle.map((b) => b.current).filter((b) => b.region === selectedSettlement);

    return {
      weatherIcons,
      forecast: selectedSettlementData?.forecast,
      current: {
        celsius: selectedSettlementData?.current?.celsius,
        fahrenheit: selectedSettlementData?.current?.fahrenheit,
        iconUrl: selectedSettlementData?.current?.iconUrl,
        iconMsg: selectedSettlementData?.current?.iconMsg,
      },
    };
  }

  getWeatherDataBySettlements$(selectedSettlement: string, settlements: string[] = [selectedSettlement]): Observable<BundledWeatherData> {
    let params = new HttpParams();
    settlements.forEach((settlement: string) => {
      params = params.append(`settlements[]`, settlement);
    });

    let forecast;
    let weather;

    if (environment?.production) {
      // production
      /*  forecast = this.reqService.get(`/${this.lang}/forecast`, { params });
      weather = this.reqService.get(`/${this.lang}/weather`, { params });
   */
    } else {
      // develop
      /*  forecast = this.reqService.get(`${this.weatherApiUrl}/weatherapi/v1/getForecastBySettlement?settlements={${settlements.toString()}}`);
      weather = this.reqService.get(`${this.weatherApiUrl}/weatherapi/v1/getWeatherBySettlement?settlements={${settlements.toString()}}`);
    */
    }

    return forkJoin([forecast, weather]).pipe(
      map((weatherData: [ForecastBySettlement[], WeatherBySettlement[]]) => {
        const weatherDataBundle = this.bundleWeatherData(weatherData);
        return {
          meta: this.getMeta(weatherDataBundle),
          selected: this.getSelectedData(weatherDataBundle, selectedSettlement),
          national: this.getNationalData(weatherDataBundle),
        };
      })
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { DateLabelService } from '../../services/date-label.service';
import { TrendencyUtilsService } from '../../../../../trendency/utils';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss'],
})
export class OpeningHoursComponent implements OnInit {
  @Input() opening: any;

  openingState: {
    from: string;
    to: string;
  };
  openingFormatted: {
    hours: Array<any>;
    nonstop: boolean;
  };

  constructor(private readonly dateLabelService: DateLabelService, private readonly utilsService: TrendencyUtilsService) {}

  ngOnInit(): void {
    this.openingFormatted = this.getOpeningHours();
  }

  formatTime(time: string): string {
    return this.dateLabelService.formatTime(time);
  }

  getOpeningHours(): { hours: Array<any>; nonstop: boolean } {
    if (
      !this.opening?.length &&
      !this.opening[0]?.openingSeason?.length &&
      !this.opening[0]?.openingSeason[0]?.openingHours?.length &&
      !this.opening[0]?.openingHours?.length
    ) {
      return undefined;
    }

    const openingSeason = this.opening[0] && this.opening[0]?.openingSeason?.length ? this.opening[0]?.openingSeason[0] : null;

    if (openingSeason?.from_monthAndDay && openingSeason?.to_monthAndDay) {
      this.openingState = {
        from: this.utilsService.sliceStringAfterCharacter(openingSeason.from_monthAndDay, '#'),
        to: this.utilsService.sliceStringAfterCharacter(openingSeason.to_monthAndDay, '#'),
      };
    }

    const openingHours = openingSeason?.openingHours ?? this.opening[0].openingHours;
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    if (openingHours.length === 1 && this.utilsService.sliceStringAfterCharacter(openingHours[0].day_of_week, '#') === 'allday') {
      return {
        hours: [],
        nonstop: true,
      };
    } else {
      const hours = days.reduce((acc, day) => {
        const dayObj = openingHours.find((item) => {
          item.day_of_week = this.utilsService.sliceStringAfterCharacter(item.day_of_week, '#');
          return item?.day_of_week === day;
        });

        dayObj?.day_of_week ? acc.push(dayObj) : acc.push({ day_of_week: day });

        return acc;
      }, []);

      return {
        hours,
        nonstop: false,
      };
    }
  }

  getDateWithoutYear(str: string): string {
    if (!str) {
      return undefined;
    }
    return str.slice(5, str.length);
  }

  isFromJan1ToDec31(date1: string, date2: string): boolean {
    return date1 === 'january1' && date2 === 'december31';
  }

  dateLabel(date1: string, date2?: string): string {
    if (!date2) {
      return this.dateLabelService.createLabelSingle(date1);
    }
    return this.dateLabelService.createLabel(date1, date2);
  }
}

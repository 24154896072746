<section class="videos" *ngIf="videoList?.length">
  <div *ngIf="componentObject?.thematicIcon" [ngClass]="'specicon ' + componentObject?.thematicIcon?.key" class="specicon"></div>
  <div class="videos-container">
    <div class="head-section">
      <h2>{{ componentObject?.titleText | uppercase }}</h2>
      <p class="description">{{ componentObject?.subTitleText }}</p>
    </div>
    <div class="content" [ngClass]="{ active: videoList?.length > 1 }">
      <div class="play-container">
        <div
          *ngIf="!isPlaying && currentImage && currentVideo?.srcName !== 'teka'"
          class="wrapper"
          [ngStyle]="{ 'background-image': currentImage ? 'url(' + currentImage + ')' : 'none' }"
          (click)="playToggleMobile()"
        >
          <button *ngIf="!isPlaying" class="toggle-button play" (click)="playVideo()"></button>
          <button *ngIf="isPlaying" class="toggle-button pause" (click)="stopVideo()"></button>
          <div class="text-container desktop-text">
            <span class="title" *ngIf="currentVideo?.title">{{ currentVideo.title | uppercase }}</span>
            <span class="desc" *ngIf="currentVideo?.description">{{ currentVideo.description }}</span>
          </div>
        </div>
        <app-youtube-player class="video" *ngIf="currentVideo?.srcName === 'youtube'" [videoId]="currentVideoLink"></app-youtube-player>
        <app-video-player
          class="video"
          *ngIf="currentVideo?.srcName === 'teka'"
          [url]="currentVideo.src"
          [autoplay]="false"
        ></app-video-player>
      </div>
      <div class="text-container mobile-text">
        <span class="title" *ngIf="currentVideo?.title">{{ currentVideo.title | uppercase }}</span>
        <span class="desc" *ngIf="currentVideo?.description">{{ currentVideo.description }}</span>
      </div>
      <div class="list-container" *ngIf="videoList?.length > 1">
        <div class="desktop">
          <ng-container [ngTemplateOutlet]="cards"></ng-container>
        </div>
        <div class="mobile carousel" #carousel>
          <ng-container [ngTemplateOutlet]="cards"></ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="componentObject?.buttonText" class="button-container" [ngClass]="{ active: videoList?.length > 1 }">
      <button class="button button-primary" (click)="goTo(componentObject?.buttonLink)" [appBackgroundColor]="'red'">
        {{ componentObject?.buttonText | uppercase }}
      </button>
    </div>
  </div>
</section>
<ng-template #cards>
  <div *ngFor="let video of videoList" [ngClass]="{ active: video.id === currentVideo.id }" class="card" (click)="changeVideoMobile(video)">
    <button class="play-button-small" (click)="selectVideo(video)"></button>
    <span (click)="selectVideo(video)">{{ video?.title }}</span>
  </div>
</ng-template>

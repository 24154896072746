import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { BaseComponent } from '../base.component';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleToIdPipe } from '../../pipes/title-to-id.pipe';

@Component({
  selector: 'app-anchor-block',
  templateUrl: './anchor-block.component.html',
  styleUrls: ['./anchor-block.component.scss'],
})
export class AnchorBlockComponent extends BaseComponent implements OnInit {
  rowColor: string;
  listOpen = false;
  url: string;
  routerLink: any[];
  isSticky: boolean;
  headerOffsetHeight: number;
  headerOffsetWidth: number;
  componentOffsetTop: number;
  componentOffsetHeight: number;
  @ViewChild('anchorBlock', { static: true }) anchorBlock: ElementRef;
  @ViewChild('anchorBlockContent', { static: true }) anchorBlockContent: ElementRef;

  constructor(
    private readonly router: Router,
    private readonly sharedService: SharedService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleToId: TitleToIdPipe,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.url = this.router.url.split('#')[0];
    this.getRowColor();
    this.scrollTo(this.activatedRoute.snapshot.fragment);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.getComponentOffsetTop();
    this.getComponentOffsetHeight();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    this.getComponentOffsetHeight();
    this.stickyHandling();
  }

  getComponentOffsetHeight(): number {
    const offsetHeight = this.anchorBlockContent.nativeElement.offsetHeight;
    return (this.componentOffsetHeight = offsetHeight ?? 0);
  }

  getComponentOffsetTop(): number {
    const offsetTop = this.anchorBlock.nativeElement.offsetTop;
    return (this.componentOffsetTop = offsetTop ?? 0);
  }

  getMobileSpaceOffsetHeight(): number {
    const spacer = document.querySelector('header.mobile .fixed-spacer') as HTMLElement;
    return spacer?.offsetHeight ?? 0;
  }

  getHeaderOffsetHeight(): number {
    this.cd.detectChanges();
    const headerDesktop = document.querySelector('header.desktop') as HTMLElement;
    const headerMobile = document.querySelector('header.mobile') as HTMLElement;
    const headTopDesktop = document.querySelector('header.desktop .head-top') as HTMLElement;

    if (headerDesktop && headerMobile && headTopDesktop) {
      const headerMobileHeight = headerMobile.classList.contains('sticky') ? this.getMobileSpaceOffsetHeight() : 0;
      return (this.headerOffsetHeight = headerDesktop.offsetHeight + headerMobileHeight - headTopDesktop.offsetHeight);
    }

    return 0;
  }

  getHeaderOffsetWidth(): number {
    const navWidth = document.querySelector('header.desktop .main-nav') as HTMLElement;
    return (this.headerOffsetWidth = navWidth?.offsetWidth ?? 0);
  }

  stickyHandling(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isSticky = window.pageYOffset >= this.getComponentOffsetTop() - this.getHeaderOffsetHeight();
    }
  }

  public toggleList(): void {
    this.listOpen = !this.listOpen;
    setTimeout(() => {
      this.onScroll();
    }, 40);
  }

  public scrollTo(component: any): void {
    const url = this.router
      .createUrlTree([], { relativeTo: this.activatedRoute, fragment: this.titleToId.transform(component) })
      .toString();
    this.router.navigateByUrl(url);
    this.listOpen = false;
    this.cd.detectChanges();

    const space = 20;
    const compHeight = this.getComponentOffsetHeight();
    const headerHeight = this.getHeaderOffsetHeight();
    const element = document.getElementById(this.titleToId.transform(component));
    const current = document.documentElement.scrollTop;
    let y = element.getBoundingClientRect().top + window.scrollY;
    let yOffset = -compHeight - headerHeight - space;

    if (this.sharedService.isMobile()) {
      yOffset = current > y ? -compHeight - this.getMobileSpaceOffsetHeight() - space : -compHeight - space;
    }

    y = y + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  getRowColor(): void {
    if (this.componentObject.color) {
      switch (this.componentObject.color.value) {
        case 'EF4E69':
          {
            this.rowColor = 'red';
          }
          break;
        case '7C51A1':
          {
            this.rowColor = 'purple';
          }
          break;
        case '38BA9B':
          {
            this.rowColor = 'green';
          }
          break;
        case '6499D1':
          {
            this.rowColor = 'blue-light';
          }
          break;
      }
    } else {
      this.rowColor = 'blue-light';
    }
  }
}

import { Injectable } from '@angular/core';
import { ITekaImageObject, ITekaThumbnailImage } from '../shared.definitions';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TekaService {
  constructor(private readonly translateService: TranslateService) {}

  getImage(data, quality: string): ITekaImageObject {
    if (!data?.tekaFiles?.payload?.images?.length) {
      return undefined;
    }

    const image = data.tekaFiles.payload.images.find((item) => item.orderNumber === 1);

    if (!image?.files?.length) {
      return undefined;
    }

    return {
      ...image.files.find((img) => img.quality === quality),
      image: {
        title: this.getTekaImageDescription(data),
      },
    };
  }

  getThumbnailImage(data): ITekaThumbnailImage {
    // const thumbnail = data?.entity?.thumbnail;
    const indexImageUrl = data?.tekaData?.payload[0]?.indexImageUrl;

    // if (!thumbnail && !indexImageUrl) {
    if (!indexImageUrl) {
      return null;
    }

    return {
      // url: indexImageUrl ?? thumbnail,
      url: indexImageUrl,
      image: {
        title: this.getTekaImageDescription(data),
      },
    };
  }

  getIconImageByCategory(item): string {
    const category = item?.entity?.category && item?.entity?.category[0];
    if (!category) {
      return null;
    }

    const catEntries = Object.entries(category);
    if (!catEntries?.length) {
      return null;
    }

    return `/assets/images/filter-icons/icon_${catEntries[0][0]}.svg`;
  }

  getTekaImageDescription(data: any): string {
    return data?.tekaData?.payload?.title ? data.tekaData.payload.title[this.translateService.currentLang] : '';
  }
}

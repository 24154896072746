import { Component, OnInit } from '@angular/core';
import { IFilterOption, IApplicationFilters } from '../../shared.definitions';
import { ApiService } from '../../services/api.service';
import { BaseComponent } from '../base.component';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-application-collection-collection',
  templateUrl: './application-collection.component.html',
  styleUrls: ['./application-collection.component.scss'],
})
export class ApplicationCollectionComponent extends BaseComponent implements OnInit {
  subtitle = 'listing page subtitle';
  applications: any[];
  applicationLength: number;
  activeFilters: IApplicationFilters;
  applicationMaxLength: number;
  applicationsPerPage = 1000;
  limitMultiplier = 1;
  type: string;
  uuid: string;
  searchControl: FormControl;
  title: string;

  constructor(private readonly apiService: ApiService, private readonly translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    if (this.componentObject?.contentPageOnlyCampaign?.uuid) {
      this.type = 'campaign';
      this.uuid = this.componentObject?.contentPageOnlyCampaign?.uuid;
      this.title = this.componentObject?.contentPageOnlyCampaign?.title;
      this.defaultRequest();
    }

    if (this.componentObject.contentPageOnlyRestaurant?.uuid) {
      this.type = 'restaurant';
      this.uuid = this.componentObject?.contentPageOnlyRestaurant?.uuid;
      this.title = this.componentObject?.contentPageOnlyRestaurant?.title;
      this.defaultRequest();
    }

    if (this.componentObject.contentPageOnlySummerCampaign?.uuid) {
      this.type = 'summer-accomodation';
      this.uuid = this.componentObject?.contentPageOnlySummerCampaign?.uuid;
      this.title = this.componentObject?.contentPageOnlySummerCampaign?.title;
      this.defaultRequest();
    }
  }

  defaultRequest(): void {
    const filters = {
      search: '',
      destinations: [],
      serviceTypes: [],
      summerServices: [],
    };
    this.activeFilters = {
      search: '',
      destinations: [],
      serviceTypes: [],
      summerServices: [],
    };
    if (this.type === 'summer-accomodation') {
      filters.summerServices = [
        { id: 'typeAccommodation', text: this.translateService.instant(`CAMPAIGN.type.accommodation`), checked: false },
        { id: 'typeCateringUnit', text: this.translateService.instant(`CAMPAIGN.type.cateringunit`), checked: false },
        { id: 'typeAttraction', text: this.translateService.instant(`CAMPAIGN.type.attraction`), checked: false },
        { id: 'typeBathingPlace', text: this.translateService.instant(`CAMPAIGN.type.bathingplace`), checked: false },
      ];
    }
    this.searchControl = new FormControl(this.activeFilters.search);
    this.applications = [];
    this.apiService.getApplications$(this.type, this.uuid, 0, this.applicationsPerPage * this.limitMultiplier).subscribe(
      (res) => {
        if (res?.meta?.filterOptions?.destinationTag_filter) {
          Object.keys(res.meta.filterOptions?.destinationTag_filter).forEach((key) => {
            filters.destinations.push({
              id: +key,
              text: this.translateService.instant(`CAMPAIGN.destination.${res.meta.filterOptions.destinationTag_filter[key]}`),
              checked: false,
            });
          });
        }
        if (res?.meta?.filterOptions?.serviceTypes_filter) {
          Object.keys(res.meta.filterOptions?.serviceTypes_filter).forEach((key) => {
            filters.serviceTypes.push({
              id: +key,
              text: res.meta.filterOptions.serviceTypes_filter[key],
              checked: false,
            });
          });
        }

        if (this.type === 'summer-accomodation') {
          filters.destinations.push({
            id: 0,
            text: this.translateService.instant('CAMPAIGN.destination.other'),
            checked: false,
          });
        }

        this.activeFilters = filters;
        this.applications = res.data;
        this.applicationMaxLength = res.meta.limit.allRowCount;
        this.applicationLength = this.applications.length;
        // console.log(this.applications);
      },
      () => {
        // TODO: add error handling?
      }
    );
  }

  public onSearch(): void {
    this.activeFilters.search = this.searchControl.value;
    this.filterRequest(false);
  }

  filterRequest(isAddition: boolean): void {
    this.apiService
      .getApplications$(
        this.type,
        this.uuid,
        this.applicationsPerPage * (this.limitMultiplier - 1),
        this.applicationsPerPage * this.limitMultiplier,
        this.activeFilters.search,
        this.activeFilters.destinations,
        this.activeFilters.serviceTypes,
        this.activeFilters.summerServices
      )
      .subscribe(
        (res) => {
          if (isAddition) {
            this.applications = [...this.applications, ...res.data];
          } else {
            this.applications = res.data;
          }
          this.applicationMaxLength = res.meta.limit.allRowCount;
          this.applicationLength = this.applications.length;
        },
        () => {
          // TODO: Add error handling?
        }
      );
  }

  loadMore(): void {
    this.limitMultiplier += 1;
    this.filterRequest(true);
  }

  onFilterChange(event: IApplicationFilters): void {
    this.activeFilters = event;
    this.limitMultiplier = 1;
    this.filterRequest(false);
  }

  summerServicesFilterActive(): any {
    return this.activeFilters.summerServices.findIndex((f) => f.checked) > -1;
  }

  serviceTypesFilterActive(): any {
    return this.activeFilters.serviceTypes.findIndex((f) => f.checked) > -1;
  }

  destinationFilterActive(): any {
    return this.activeFilters.destinations.findIndex((f) => f.checked) > -1;
  }

  onFilterClear(filter: string): void {
    this.activeFilters[filter].forEach((f) => {
      f.checked = false;
    });
    this.filterRequest(false);
  }

  removeFilterElement(filterOption: IFilterOption): void {
    filterOption.checked = false;
    this.activeFilters = JSON.parse(JSON.stringify(this.activeFilters));
    this.filterRequest(false);
  }

  clearFilters(): void {
    this.defaultRequest();
  }

  onOptionClick(option: IFilterOption): void {
    option.checked = !option.checked;
    this.limitMultiplier = 1;
    this.filterRequest(false);
  }
}

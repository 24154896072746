<section class="detailed-slider" #mainWrapper>
  <div class="section-head">
    <h2>{{ title }}</h2>
    <p class="desc">{{ subtitle }}</p>
  </div>

  <div
    #detailedCarouselObjElement
    class="wrapper"
    config="[carouselOptions]"
    class="detailed-carousel-obj"
    [ngClass]="{ 'move-anim-init': moveAnimInited }"
    (click)="goToPos($event)"
  >
    <ng-container *ngFor="let card of cards">
      <div class="card-wrapper">
        <app-card-w-desc [data]="card" [isSlider]="true"></app-card-w-desc>
      </div>
    </ng-container>
  </div>
  <div class="wrapper wpadding">
    <a *ngIf="buttonLabel && buttonUrl" [href]="buttonUrl" class="button button-primary" [appBackgroundColor]="'red'">{{ buttonLabel }}</a>
  </div>
</section>

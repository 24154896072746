<section class="podcast" [appBackgroundColor]="'blue-light'" *ngIf="link">
  <div class="container">
    <h2 class="podcast__title" *ngIf="componentObject?.title">{{ componentObject.title }}</h2>
    <span class="podcast__subtitle" *ngIf="componentObject?.subTitle">{{ componentObject.subTitle }}</span>

    <article class="podcast-article" *ngIf="!isEmbed?.length">
      <span
        class="podcast-article__image"
        *ngIf="componentObject?.image?.url"
        [ngStyle]="{ 'background-image': 'url(' + componentObject?.image?.url + ')' } | ssrEmptyString"
        [attr.aria-label]="componentObject?.image?.image?.title"
        role="img"
        corporateImageLazyLoad
      ></span>
      <div class="podcast-article__content">
        <h3 *ngIf="componentObject?.podcastTitle">{{ componentObject.podcastTitle }}</h3>
        <p *ngIf="componentObject?.podcastDescription">{{ componentObject.podcastDescription }}</p>
      </div>
      <a [href]="link" class="podcast-article__link" target="_blank" aria-label="podcast link" *ngIf="link"></a>
    </article>

    <iframe *ngIf="isEmbed === 'soundcloud'" [src]="link | safe: 'resourceUrl'" width="100%" allowfullscreen=""></iframe>
    <iframe *ngIf="isEmbed === 'spotify'" [src]="link | safe: 'resourceUrl'" width="100%" height="300" allowfullscreen=""></iframe>
  </div>
</section>
